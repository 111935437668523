import { Divider, Drawer } from "antd";

const ImageDetails = ({ currentData, album , open, onClose }) => {
  return      <Drawer
  title={null}
  placement="left"
  onClose={onClose}
  open={open}
  style={{ paddingBottom: 40 }}
>
  {currentData && (
    <>
      <p
        style={{
          fontSize: 18,
          marginBottom: 5,
          fontWeight: "bold",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          overflow: "hidden",
          display: "block",
        }}
      >
        {currentData.name ? currentData.name : album.name}
      </p>
      <p
        style={{
          fontSize: 12,
          margin: 0,
          fontSize: 14,
          color: "gray",
        }}
      >
        {(currentData.fileSize / 1048576).toFixed(2)} MB
      </p>
      <p style={{ fontSize: 15, fontWeight: "bold" }}>
        Information
      </p>
      <Divider style={{ margin: "13px 0" }} />

      <p
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {" "}
        <span style={{ color: "gray" }}>Album</span>
        <span
          style={{
            color: "black",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "right",
            overflow: "hidden",
            display: "block",
          }}
        >
          {album.name}
        </span>
      </p>
      <Divider style={{ margin: "13px 0" }} />
      <p
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {" "}
        <span style={{ color: "gray" }}>Original filename</span>
        <span
          style={{
            color: "black",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            textAlign: "right",
            overflow: "hidden",
            display: "block",
          }}
        >
          {currentData.name
            ? currentData.name
            : currentData.fileName}
        </span>
      </p>
      <Divider style={{ margin: "13px 0" }} />

      <p
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {" "}
        <span style={{ color: "gray" }}>Resolution</span>
        <span style={{ color: "black" }}>
          {currentData.width} x {currentData.height}
        </span>
      </p>
      <Divider style={{ margin: "13px 0" }} />
      <p
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {" "}
        <span style={{ color: "gray" }}>File size</span>
        <span style={{ color: "black" }}>
          {(currentData.fileSize / 1048576).toFixed(2)} MB
        </span>
      </p>
      <Divider style={{ margin: "13px 0" }} />
      <p
        style={{
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 12,
        }}
      >
        {" "}
        <span style={{ color: "gray" }}>Uploaded at</span>
        <span style={{ color: "black" }}>
          {new Date(currentData.createdAt).toUTCString()}
        </span>
      </p>
    </>
  )}
</Drawer>;
};
export default ImageDetails;
