import InfiniteScroll from "react-infinite-scroll-component";
import "./images.css";
import {
  CheckSquareOutlined,
  PlusOutlined,
  DeleteOutlined,
  BlockOutlined,
  CopyOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Divider,
  List,
  theme,
  message,
  Spin,
  Button,
  Result,
  Empty,
  FloatButton,
} from "antd";
import axios from "axios";

import { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../components/defaultLayout";
import { Album } from "../../api/album";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINT, IMAGEBASEPATH } from "../../environment";
import { FullScreenImageUploadProgress } from "../../components/FullScreenImageUploadProgress";
import { Image } from "../../api/image";
import { AlbumsModalSelector } from "../../components/AlbumsModalSelector";
import ImageDetails from "./ImageDetails";
import ImageViewer from "./ImageViewer";

const Images = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [imageViewerVisible, setImageViewerVisible] = useState(false);
  const [copyImagesModalVisible, setCopyImagesModalVisible] =
    useState(false);
  const [moveImagesModalVisible, setMoveImagesModalVisible] =
    useState(false);
  const [images, setImages] = useState([]);
  const [album, setAlbum] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [currentData, setCurrentData] = useState();
  const [currentDataIndex, setCurrentDataIndex] = useState();
  const [open, setOpen] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [albumNotFound, setAlbumNotFound] = useState();
  const [progression, setProgression] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [modeSelectEnabled, setModeSelectEnabled] = useState(false);
  const inputFileRef = useRef(null);
  var uploadedFileCount = 0;
  const uploadSizeLimit = 150; // MB
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };
  const closeImageViewer = () => {
    setImageViewerVisible(false);
  };

  const confirmDelete = async () => {
    message.success("Image removed");
    setLoading(true);
    setImageViewerVisible(false);
    setOpen(false);
    await Image.remove({ imageIds: [currentData.id] });
    getAlbumData();
  };

  const handleClick = (e, item, index) => {
    switch (e.detail) {
      case 2:
        console.log("double click");
        // setCurrentData(item);
        // setCurrentDataIndex(index);
        // setImageViewerVisible(true)

        break;
      case 1:
        console.log("click");
        if (modeSelectEnabled) {
          setSelectedElements((prevEls) =>
            prevEls.includes(item.id)
              ? prevEls.filter((e) => e !== item.id)
              : [...prevEls, item.id]
          );
        } else {
          setCurrentData(item);
          setCurrentDataIndex(index);
          // showDrawer();
          setImageViewerVisible(true);
        }

        break;
      case 3:
        console.log("triple click");
        break;
    }
  };

  const onClose = () => {
    setOpen(false);
  };
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    let newImages = allImages.slice(images.length, images.length + 50);
    setImages([...images, ...newImages]);
  };

  const incrementDataCursor = (incr) => {
    if (allImages[currentDataIndex + incr]) {
      setCurrentDataIndex(currentDataIndex + incr);
      setCurrentData(allImages[currentDataIndex + incr]);
      console.log(currentDataIndex + incr);
    }
  };

  const arrowFunction = (event) => {
    var incr;
    if (event.key === "ArrowLeft") {
      incr = -1;
    } else if (event.key === "ArrowRight") {
      incr = 1;
    }
    incrementDataCursor(incr);
  };
  function dragenterDragleave(e) {
    var dragCount = 0,
      dropzone = document.querySelector("body");
    e.preventDefault();
    dragCount += e.type === "dragenter" ? 1 : -1;
    if (dragCount === 1) {
      console.log("entred");
    } else if (dragCount === 0) {
      console.log("sort");
    }
  }

  const saveDocuments = (files) => {
    uploadedFileCount = 0;
    setProgression(new Array(files.length-1));
     try {
      files.forEach((file) => {
        let formData = new FormData();
        console.log(file);
        let url = window.URL.createObjectURL(file);

        // console.log(url)

        formData.append("file", file, file.name);
        formData.append("album", params._id);

        axios
          .post(`${ENDPOINT}/image/upload`, formData, {
            headers: {
              // "X-CSRFTOKEN": getCookie("csrftoken"),
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // Create copy of file
              let newFile = file;
              // ... and set the progress
              newFile.progress = percentCompleted;
              // Create copy of files array
              let filesCopy = [...files];
              // Find the index of the file object
              let fileIndex = filesCopy.findIndex(
                (el) => el.name === file.name
              );
              //... and replace the object with the new one containing the [progress] key
              filesCopy[fileIndex] = newFile;
              // Finally, update the state
              setFilesUploaded([...filesCopy]);

              console.log(fileIndex, percentCompleted);
              file.progress = percentCompleted;
              let progression_tmp = progression
              progression_tmp[fileIndex] = percentCompleted
             setProgression(progression_tmp)

              file.url = url;
            },
          })
          .then((response) => {
            console.log(response.data.error);
            console.log(response.data.message);
            if (response.data.error === true) {
              message.error("Upload error : " + response.data.message);
              // message.error("Upload error !");
            }
            console.log(response);
            uploadedFileCount++;
console.log("-----> ",  uploadedFileCount)
 
            if (  uploadedFileCount===files.length && getProgressionValue() === 100) {
              setProgression([]);
              uploadedFileCount = 0
              getAlbumData();
            }
      
          })
          .catch((e) => {
            console.log(e);
            uploadedFileCount++;

             let filesCopy = [...files];

            let fileIndex = filesCopy.findIndex(
              (el) => el.name === file.name
            );
           
            let progression_tmp = progression
            progression_tmp[fileIndex] = 100
           setProgression(progression_tmp)

             if (   uploadedFileCount===files.length && getProgressionValue() === 100) {
              setProgression([]);
              uploadedFileCount = 0
              getAlbumData();
            }
          });
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleFile = (e) => {
     console.log(e.target.files);

    let files = [...e.target.files].filter((file) => {
      if (file.size / 1048576 > uploadSizeLimit) {
        message.warning(
          `${file.name} (${(file.size / 1048576).toFixed(
            2
          )} MB) Could not be uploaded beacause of file size limite : ${uploadSizeLimit} MB`
        );
        return false;
      } else return true;
    });

 
    // setFiles(e.target.files);
    saveDocuments(files);
  };

  const getAlbumData = async () => {
    setLoading(true);
    Album.get({ _id: params._id })
      .then((data) => {
        if (data.code === 400) {
          message.error(`Album not found`);
          setLoading(false);
          setAlbumNotFound(true);
          return;
        }
        setAlbumNotFound(false);
        setAlbum(data);
        setImages(data.images.slice(0, 50));
        setAllImages(data.images);

        console.log(data);

        setLoading(false);
      })
      .catch((e) => {
        console.log(e)
        message.error(`An error occured !`);
        setLoading(false);
      });
  };
  useEffect(
    (currentDataIndex) => {
      if (!allImages.length) {
        console.log(params);

        getAlbumData();
      }

      document.addEventListener("keydown", arrowFunction, false);

      return () => {
        // unsubscribe event
        document.removeEventListener("keydown", arrowFunction);
      };
    },
    [currentDataIndex]
  );

  const copyImages = async (toAlbum) => {
    console.log(toAlbum, selectedElements, params._id);

    setCopyImagesModalVisible(false);
    setLoading(true);

    Image.copy({
      from: params._id,
      to: toAlbum,
      imageIds: selectedElements,
    })
      .then(function (response) {
        console.log(response);
        setLoading(false);
        message.success(`Copied ${selectedElements.length} images`);
        window.location.href = `/album/${toAlbum}`;
      })
      .catch((e) => {
        message.error(`An error occured while copying the images`);
        setLoading(false);
      });
  };


  const getProgressionValue = () => {
    console.log(progression)
    console.log(progression.length)
    if(progression && progression.length) {
     return( progression.reduce((partialSum, a) => partialSum + (a ? a : 0), 0))/progression.length
    }
    else return null
  }
  const moveImages = async (toAlbum) => {
    console.log(toAlbum, selectedElements, params._id);

    setMoveImagesModalVisible(false);
    setLoading(true);

    Image.move({
      from: params._id,
      to: toAlbum,
      imageIds: selectedElements,
    })
      .then(function (response) {
        console.log(response);
        setLoading(false);
        message.success(`Moved ${selectedElements.length} images`);
        window.location.href = `/album/${toAlbum}`;
      })
      .catch((e) => {
        message.error(`An error occured while copying the images`);
        setLoading(false);
      });
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <DefaultLayout
      title={album && album.name ? "Album " + album.name : null}
      subtitle={
        modeSelectEnabled && selectedElements.length
          ? `${selectedElements.length} Selected`
          : album && album.images
          ? `${album.images.length}`
          : null
      }
    >
      <Spin
        style={{ maxHeight: "85vh", height: "85vh" }}
        spinning={loading}
        delay={500}
      >
        {moveImagesModalVisible && (
          <AlbumsModalSelector
            title={"Move To"}
            close={() => setMoveImagesModalVisible(false)}
            callback={moveImages}
          ></AlbumsModalSelector>
        )}
        {copyImagesModalVisible && (
          <AlbumsModalSelector
            title={"Copy To"}
            close={() => setCopyImagesModalVisible(false)}
            callback={copyImages}
          ></AlbumsModalSelector>
        )}
        { getProgressionValue()!==null   && (
          <FullScreenImageUploadProgress
            title={"Envoie des images en cours"}
            subtitle={null}
            progress={getProgressionValue()}
          ></FullScreenImageUploadProgress>
        )}
        <input
          ref={inputFileRef}
          type="file"
          name="images"
          id="uploadInput"
          accept="image/jpeg, image/png"
          hidden
          onChange={handleFile}
          multiple
        />

        {!loading && (
          <FloatButton.Group
            shape="circle"
            style={{
              right: 24,
              zIndex: 1,
            }}
          >
            {!modeSelectEnabled && (
              <FloatButton
                tooltip={<div>Add Images</div>}
                onClick={() => inputFileRef.current.click()}
                type="primary"
                icon={<PlusOutlined />}
              />
            )}

            {modeSelectEnabled && selectedElements.length > 0 && (
              <>
                <FloatButton
                  tooltip={<div>Copy</div>}
                  onClick={() => setCopyImagesModalVisible(true)}
                  type="primary"
                  icon={<CopyOutlined />}
                />
                <FloatButton
                  tooltip={<div>Move</div>}
                  onClick={() => setMoveImagesModalVisible(true)}
                  type="primary"
                  icon={<RotateRightOutlined />}
                />
                <FloatButton
                  tooltip={<div>Remove</div>}
                  onClick={async () => {
                    setLoading(true);
                    await Image.remove({ imageIds: selectedElements });
                    getAlbumData();
                    message.success(
                      selectedElements.length + " Images removed"
                    );
                    setModeSelectEnabled(false);
                    setSelectedElements([]);
                  }}
                  type="primary"
                  icon={<DeleteOutlined />}
                />
              </>
            )}
            {modeSelectEnabled && (
              <FloatButton
                tooltip={
                  <div>
                    {selectedElements.length !== images.length
                      ? "Select All"
                      : "UnSelect All"}
                  </div>
                }
                onClick={() =>
                  setSelectedElements(
                    selectedElements.length === images.length
                      ? []
                      : allImages.map((i) => i.id)
                  )
                }
                type="primary"
                icon={<BlockOutlined />}
              />
            )}
            {allImages && allImages.length > 0 && (
              <FloatButton
                tooltip={<div>Select Mode</div>}
                onClick={() => {
                  if (!modeSelectEnabled) {
                    setSelectedElements([]);
                  }
                  message.info(
                    "Select Mode " +
                      (!modeSelectEnabled ? "Enabled" : "Disabled")
                  );
                  setModeSelectEnabled(!modeSelectEnabled);
                }}
                type="primary"
                icon={<CheckSquareOutlined />}
              />
            )}

            <FloatButton.BackTop
              tooltip={<div>Go to Top</div>}
              type="primary"
            />
          </FloatButton.Group>
        )}

        {imageViewerVisible && currentData && currentData.fileName && (
          <ImageViewer
            showDrawer={showDrawer}
            incrementDataCursor={incrementDataCursor}
            closeImageViewer={closeImageViewer}
            currentData={currentData}
            confirmDelete={confirmDelete}
            currentDataIndex={currentDataIndex}
            allImages={allImages}
            setSelectedElements={setSelectedElements}
            setMoveImagesModalVisible={setMoveImagesModalVisible}
            setCopyImagesModalVisible={setCopyImagesModalVisible}
          ></ImageViewer>
        )}
        <Breadcrumb
          style={{
            margin: "30px 0",
          }}
        ></Breadcrumb>

        {images && (
          <>
            <ImageDetails
              currentData={currentData}
              open={open}
              onClose={onClose}
              album={album}
            ></ImageDetails>

            {albumNotFound === true && (
              <Result
                status="404"
                title="Not found"
                subTitle="Sorry, the album you visited does not exist."
                extra={
                  <Button
                    onClick={() => navigate("/albums")}
                    type="primary"
                  >
                    Back Home
                  </Button>
                }
              />
            )}
            {albumNotFound === false && album && album.images && (
              <div
                style={{
                  overflow: "auto",
                  background: images.length ? "white" : "transparent",
                  padding: 20,
                  margin: "auto",
                }}
              >
                <InfiniteScroll
                  dataLength={images.length}
                  next={loadMoreData}
                  hasMore={images.length < allImages.length}
                  endMessage={images.length ? <Divider plain></Divider> : null}
                  scrollableTarget="body"
                >
                  <List
                    dataSource={images}
                    locale={{
                      emptyText: (
                        <Empty
                          description={"Aucune Image"}
                          style={{
                            minHeight: "40vh",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            columnCount: 4,
                            columnGap: 10,
                          }}
                        ></Empty>
                      ),
                    }}
                    renderItem={(item, index) => (
                      <div
                        // onDoubleClick={()=> {
                        //   setCurrentData(item);
                        //   setImageViewerVisible(true)
                        // }}
                        key={item.id + "image"}
                        className="gallery_image"
                        onClick={(e) => handleClick(e, item, index)}
                      >
                        <img
                          style={{
                            width: "100%",
                            // aspectRatio: 1,

                            borderWidth: 4,

                            backgroundColor: item.rgb ? 
                             ( "rgb(" +
                              item.rgb.r +
                              "," +
                              item.rgb.g +
                              "," +
                              item.rgb.b +
                              ""): "#80808012"
                          }}
                          src={IMAGEBASEPATH + "images/1/" + item.fileName}
                        ></img>

                        {modeSelectEnabled ? (
                          <div
                            className={
                              selectedElements.includes(item.id)
                                ? "images_circleGallery circleGalleryChecked"
                                : "images_circleGallery circleGalleryUnchecked"
                            }
                          ></div>
                        ) : null}
                      </div>
                    )}
                  />
                </InfiniteScroll>
              </div>
            )}
          </>
        )}
      </Spin>
    </DefaultLayout>
  );
};
export default Images;
