import OpenSeaDragon from "openseadragon";
import React, { useEffect, useState } from "react";
const OpenSeaDragonViewer = ({ url, isDzi }) => {
  const [viewer, setViewer] = useState(null);
  useEffect(() => {
    console.log('isDzi' , isDzi)

    if (url && viewer) {
      console.log(url);
      //  viewer.open(image)
      InitOpenseadragon();
    }
  }, [url]);
  const InitOpenseadragon = () => {
    viewer && viewer.destroy();
    setViewer(
      OpenSeaDragon({
        id: "openSeaDragon",
        tileSources: isDzi
          ? url
          : {
              type: "image",
              url: url,
            },
        autoHideControls: false,
        showNavigationControl: false,
        showZoomControl: false,
        minZoomLevel: 0,
        maxImageCacheCount: 12,
        defaultZoomLevel: 0,
        preserveImageSizeOnResize: true,
        autoResize: true,
        opacity: 1,
        homeFillsViewer: false,
        minZoomImageRatio: 0,
        gestureSettingsMouse: {
          dblClickToZoom: false,
          clickToZoom: false,
          pinchToZoom: true,
        },
      })
    );
  };
  useEffect(() => {
    InitOpenseadragon();
    return () => {
      viewer && viewer.destroy();
    };
  }, []);
  return (
    <div
      id="openSeaDragon"
      style={{
        height: "100%",
        width: "100%",
      }}
    ></div>
  );
};
export { OpenSeaDragonViewer };
