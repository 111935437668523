import { Popconfirm, Tooltip, FloatButton } from "antd";
import { OpenSeaDragonViewer } from "../../components/OpenSeaDragonViewer";
import ImageService from "../../services/image.service";
import { IMAGEBASEPATH } from "../../environment";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  RotateRightOutlined,
  CopyOutlined,
 } from "@ant-design/icons";
const ImageViewer = ({
  showDrawer,
  incrementDataCursor,
  closeImageViewer,
  currentData,
  confirmDelete,
  currentDataIndex,
  allImages,
  setMoveImagesModalVisible,
  setSelectedElements,
  setCopyImagesModalVisible,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 2,
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          cursor: "pointer",
          fontSize: "23px",
          color: "#fff",
          zIndex: 999999,
        }}
        className="noselect"
      >
        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Tooltip placement="bottom" title={"Move to"}>
            <RotateRightOutlined
              onClick={() => {
                setSelectedElements([currentData.id]);
                setMoveImagesModalVisible(true);
              }}
              style={{
                cursor: "pointer",
                paddingRight: 15,
              }}
            />
          </Tooltip>
        </div>

        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Tooltip placement="bottom" title={"Copy to"}>
            <CopyOutlined
              onClick={() => {
                setSelectedElements([currentData.id]);
                setCopyImagesModalVisible(true);
              }}
              style={{
                cursor: "pointer",
                paddingRight: 15,
              }}
            />
          </Tooltip>
        </div>

        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Tooltip placement="bottom" title={"Downlaod"}>
            <DownloadOutlined
              onClick={() => {
                ImageService.downloadImageFromUrl(
                  IMAGEBASEPATH + "images/0/" + currentData.fileName,
                  currentData.fileName
                );
              }}
              style={{
                cursor: "pointer",
                paddingRight: 15,
              }}
            />
          </Tooltip>
        </div>

        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Popconfirm
            title={"Are you sure to delete this Image?"}
            placement="bottom"
            description={"Delete the image"}
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title={"Delete"}>
              <DeleteOutlined
                style={{
                  cursor: "pointer",
                  paddingRight: 15,
                }}
              />
            </Tooltip>
          </Popconfirm>
        </div>

        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Tooltip placement="bottom" title={"Details"}>
            <InfoCircleOutlined
              onClick={() => {
                // setImageViewerVisible(true);
                showDrawer();
              }}
              style={{
                cursor: "pointer",
                paddingRight: 15,
              }}
            />
          </Tooltip>
        </div>

        <div style={{ display: "inline-block", paddingRight: 5 }}>
          <Tooltip placement="bottom" title={"Close"}>
            <CloseOutlined
              onClick={() => {
                closeImageViewer();
              }}
              style={{
                cursor: "pointer",
                paddingRight: 15,
              }}
            />
          </Tooltip>
        </div>
      </div>

      <LeftOutlined
        onClick={() => {
          incrementDataCursor(-1);
        }}
        style={{
          position: "fixed",
          top: "49%",
          left: 15,
          cursor: "pointer",
          fontSize: "20px",
          color: "#fff",
          zIndex: 999999,
        }}
      />

      <RightOutlined
        onClick={() => {
          incrementDataCursor(1);
        }}
        style={{
          position: "fixed",
          top: "49%",
          right: 15,
          cursor: "pointer",
          fontSize: "20px",
          color: "#fff",
          zIndex: 999999,
        }}
      />
      <span
        className="noselect openseadragonCurrentImageIndex"
        style={{
          position: "fixed",
          top: 10,
          left: "48%",
          display: "inline-block",
          cursor: "pointer",
          fontSize: 10,
          color: "#fff",
          zIndex: 999999,
        }}
      >
        {currentDataIndex + 1} / {allImages.length}{" "} {!!currentData.dzi }
      </span>

      <OpenSeaDragonViewer isDzi={false} url={IMAGEBASEPATH + (currentData.dzi ?   ("images/2/"+currentData.fileName)  : ("images/0/"+currentData.fileName) )} />
      {/* <OpenSeaDragonViewer isDzi={currentData.dzi===true} url={IMAGEBASEPATH + (currentData.dzi ? ( "images/dzi/" + currentData.fileName.split('.')[0]+"/dzi.dzi") : ("images/0/"+currentData.fileName))} /> */}
 
    </div>
  );
};
export default ImageViewer;
