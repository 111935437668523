import Login from "../pages/login/login";
import Images from "../pages/images/images";
 
import authService from "../services/auth.service";
import Error500 from "../pages/500";
import Toomany from "../pages/toomany";
import Albums from "../pages/albums/albums";
    
export const routes = [
  { path: "/", component:  authService.getCurrentUser()? Albums:  Login, roles: [] },
  { path: "/albums", component:    Albums , roles: ['user'] },
  { path: "/album/:_id", component:    Images , roles: ['user'] },
  { path: "/500",  component: Error500, roles: []},
  { path: "/toomany",  component: Toomany, roles: []},
  { path: "/login", component: Login, roles: [] },
];
