import { Progress } from "antd";
import React, { useEffect, useState } from "react";
const FullScreenImageUploadProgress = ({ progress, title, subtitle }) => {
  useEffect(() => {
    return () => {};
  }, []);
  return (
    <div
      id="FullScreenImageUploadProgress"
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 400,
          minHeight: 100,
          padding: 25,
          margin: 25,
          borderRadius: 8,
          backgroundColor: "white",
        }}
      >
        <p
          style={{
            fontSize: 16,
            marginTop: 0,
            color: "rgba(0,0,0,.88)",
            fontWeight: 600,
            lineHeight: 1.5,
          }}
        >
          {title}
        </p>
        <p
          style={{
            fontSize: 14,
            lineHeight: 1.5,
          }}
        >
          {subtitle}
        </p>
        <div
          style={{
            width: "100%",
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Progress style={{ width: "70%" }} percent={parseInt(progress)} />
        </div>
      </div>
    </div>
  );
};
export { FullScreenImageUploadProgress };
