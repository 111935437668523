import React from "react";

export default class Error500 extends React.Component {
  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>An Error Occured ...</h1>
      </div>
    );
  }
}
