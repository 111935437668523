import { Auth } from "../api";
import Cookies from "universal-cookie";

class AuthService {
  login = (email, password) =>
    new Promise((res, rej) => {
      Auth.login({ email, password }).then((response) => {
        if (!response.code) {
          const cookies = new Cookies();
          cookies.set("user", JSON.stringify(response), { path: "/" });
          console.log(cookies.get("user")); // Pacman

          res({ success: true, message: `Welcome ${response.user.name} !` });
        } else {
          let errorMessage = "";
          if (!email && password) errorMessage = "Email cannot be empty.";
          if (!password && email) errorMessage = "Password cannot be empty.";
          if (!password && !email)
            errorMessage = "Email and Password cannot be empty.";
          if (response.code) errorMessage = "Incorrect email or password !";
          res({ success: false, message: errorMessage });
        }
      });
    });

  logout = async () => {
    let refreshToken = this.getRefreshToken();
    if (refreshToken && refreshToken.token)
      await Auth.logout({
        refreshToken: this.getRefreshToken().token,
      });
    const cookies = new Cookies();
    cookies.remove("user");
   
      window.location.replace("/");
 
  
  };
  getRefreshToken = () => {
    const cookies = new Cookies();
    let user = cookies.get("user");
    if (user && user.tokens && user.tokens.refresh) return user.tokens.refresh;
    else return null;
  };
  getAccessToken = () => {
    const cookies = new Cookies();
    let user = cookies.get("user");
    if (user && user.tokens && user.tokens.access) return user.tokens.access;
    else return null;
  };
  register(username, email, password) {}

  getCurrentUser() {
    const cookies = new Cookies();
    return cookies.get("user");
  }
}

export default new AuthService();
