import React from 'react';
 
export default class Toomany extends React.Component {
  render() {
    return   <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <h1>Suspicious activity detected</h1>
  </div>;
  }
}