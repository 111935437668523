import React from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { routes } from "./routes";
import { routeMiddeware } from "./middlewares/routeMiddelware";
import authService from "./services/auth.service";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Routes>
          {authService.getCurrentUser() && (
            <Route path="/login" element={<Navigate to="/" />} />
          )}

          {routes.map((r) => {
            return (
              <Route
                key={r.path}
                exact
                path={r.path}
                element={
                  routeMiddeware(r) ? (
                    <r.component params={r.params} {...this.props} />
                  ) : (
                    <Navigate
                      to={{
                        pathname: "/login",
                        // state: { from: r.path }
                      }}
                      replace
                    />
                  )
                }
              />
            );
          })}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </React.Fragment>
    );
  }
}
export default App;
