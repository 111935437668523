import { ENDPOINT } from "../environment";
import authService from "../services/auth.service";
import Cookies from "universal-cookie";

// Fetch interceptor
// const constantMock = window.fetch;
// window.fetch = async function() {
//     // Get the parameter in arguments
//     // Intercept the parameter here
//    return constantMock.apply(this, arguments)
// }

const setHeader = async (auth, contentType = "application/json") => {
  const myHeaders = new Headers();
  let userData = authService.getCurrentUser();
  let accessToken = authService.getAccessToken();
  let refreshToken = authService.getRefreshToken();
  myHeaders.append("Accept", `application/json`);
  if (contentType !== "no-content-type")
    myHeaders.append("Content-Type", contentType);
  if (
    auth &&
    (!userData ||
      !refreshToken ||
      !accessToken ||
      (refreshToken && !refreshToken.token) ||
      (accessToken && !accessToken.token) ||
      (refreshToken && !refreshToken.expires) ||
      (accessToken && !accessToken.expires))
  ) {
    const cookies = new Cookies();
    cookies.remove("user");
    window.location.replace("/");
  } else if (auth && userData && userData.tokens) {
    try {
      if (
        new Date(accessToken.expires).getTime() - 1000 <
        new Date().getTime()
      ) {
        let response = await fetch(`${ENDPOINT}auth/refresh-tokens`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken: refreshToken.token }),
        });
        response = await response.json();
        if (response.code === 401) {
          const cookies = new Cookies();

          cookies.remove("user");
          window.location.replace("/");
        }
        if (response.access) {
          userData.tokens = response;

          const cookies = new Cookies();
          let user = cookies.get("user");
          user.tokens = response;
          cookies.set("user", JSON.stringify(user), { path: "/" });
        }
      }
    } catch (error) {
      const cookies = new Cookies();
      cookies.remove("user");
      window.location.replace("/");
    }

    myHeaders.append("Authorization", "Bearer " + userData.tokens.access.token);
  }

  // if(userData) console.log(myHeaders, `Bearer${userData.tokens.refresh.token}`)
  return myHeaders;
};

const responseBody = async (response) => {
  try {
    const res = await response.json();
    return res;
  } catch (error) {
    // const res = await response.blob();
    return response;
  }

  //  if (response.status === 200) {
  //   return res;
  // } else {
  //   // // const error = res.errors ? res.errors.error || res.errors : res.error;
  //   // console.log(res)
  //   // // throw new Error("error");
  //   return
  // }
};

export const requests = {
  open: async (auth, url) => {
    await setHeader(auth);

    window.open(`${ENDPOINT}${url}`, "_blank");
  },
  popup: async (auth, url, title, config) => {
    await setHeader(auth);
    window.open(`${ENDPOINT}${url}`, title, config);
  },
  del: async (auth, url, body) => {
    let headers = await setHeader(auth);
    fetch(`${ENDPOINT}${url}`, {
      method: "DELETE",
      headers,
      body: body,
    })
      .then(responseBody)
      .catch((error) => {
        console.log(error);
        // window.location.href = "/500";
      });
  },

  get: async (auth, url) => {
    let headers = await setHeader(auth);
    return fetch(`${ENDPOINT}${url}`, {
      headers,
    })
      .then(responseBody)
      .catch((error) => {
        console.log(error);
        // window.location.href = "/500";
      });
  },
  put: async (auth, url, body) =>
    fetch(`${ENDPOINT}${url}`, {
      method: "PUT",
      headers: await setHeader(auth),
      body: body,
    })
      .then(responseBody)
      .catch((error) => {
        console.log(error);
        // window.location.href = "/500";
      }),

  post: async (auth, url, body, contentType) => {
    let headers = await setHeader(auth, contentType);
    return fetch(`${ENDPOINT}${url}`, {
      method: "POST",
      headers,
      body: body,
    })
      .then(responseBody)
      .catch((error) => {
        console.log(error);
        // window.location.href = "/500";
      });
  },
};
