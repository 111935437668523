import { FolderOutlined, LogoutOutlined, AppstoreOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import authService from "../services/auth.service";
const { Header, Content, Footer, Sider } = Layout;

const logout = async () => {
  await authService.logout();
}; 

function getItem(el, key, icon, children) {
  return {
    key,
    icon,
    // children,
    label: el,
  };
}
const items = [
  // getItem("Images", "0", <PieChartOutlined />),
  getItem(
    <Link to={"/albums"}>Albums</Link>,
    "1",
    <Link to={"/albums"}>
      <FolderOutlined />  
    </Link>
  ),
 
  getItem(
    <span onClick={() => logout()}>Logout</span>,
    "2",
    <LogoutOutlined onClick={() => logout()} />
  ),
];

const DefaultLayout = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: "0.2s",
          minHeight: "100vh",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              lineHeight: 1.4,
              paddingLeft: 25,
            }}
          >
            {props.title && (
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                {props.title}
              </span>
            )}
            {props.subtitle && (
              <span style={{ fontSize: 10, color: "gray" }}>
                {" "}
                {props.subtitle}
              </span>
            )}
          </div>
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          {props.children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          ALBUMS ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default DefaultLayout;
