import "./login.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, Spin, message } from "antd";
import { useEffect, useState } from "react";
import authService from "../../services/auth.service";
const Login = () => {
  useEffect(() => {
    // let images = [
    //   "/backgrounds/1.jpg",
    //   "/backgrounds/2.jpg",
    //   "/backgrounds/3.jpg",
    //   "/backgrounds/4.jpg",
    //   "/backgrounds/5.jpg",
    //   "/backgrounds/6.jpg",
    //   "/backgrounds/7.jpg",
    //   "/backgrounds/8.jpg",
    //   "/backgrounds/9.jpg",
    // ];
    // // let images = ["/backgrounds/1.jpg"  ]
    // let image = images[Math.floor(Math.random() * images.length)];
    // let backgroundEl = document.querySelector(".background-image");
    // backgroundEl.style.background =
    //   "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" +
    //   image +
    //   "')";
    // backgroundEl.style.backgroundSize = "cover";
    // backgroundEl.style.backgroundPosition = "right";

    return () => {};
  }, []);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [messageApi, contextHolder] = message.useMessage();
  const openNotification = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const validateForm = async () => {
    if (!email) {
      openNotification("Email cannot be empty.");
      // notify("error", "Email cannot be empty.");
    }
    if (!password) {
      openNotification("Password cannot be empty.");
      // notify("error", "Password cannot be empty.");
    }
    if (password && email) {
      setLoading(true);

      let response = await authService.login(email, password);
      if (!response.success) {
        setLoading(false);
        openNotification("Incorrect email or password !");
      }
      if (response.success) {
        window.location.href = "/";
      }
    }
  };

  return (
    <Spin
      style={{ maxHeight: "100vh", height: "100vh" }}
      spinning={loading}
      delay={500}
    >
      {contextHolder}
      <Layout>
        <div className="background-image"></div>
        <div className="content">
          <div id="form-container">
            <Form onSubmit={() => {}} className="login-form">
              <h1>Bienvenue !</h1>
              <Form.Item>
                <Input
                  prefix={<UserOutlined style={{ color: "gray" }} />}
                  placeholder="E-mail"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  prefix={<LockOutlined style={{ color: "gray" }} />}
                  type="password"
                  autoComplete="on"
                  placeholder="Mot de passe"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                  onClick={() => {
                    validateForm();
                  }}
                >
                  Connexion
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </Spin>
  );
};
export default Login;
