import Cookies from "universal-cookie";

export const routeMiddeware = (route) => {
  const cookies = new Cookies();
  let userData = cookies.get("user");
  // alert("routeMiddeware")
  let currentUsrRoles = [];
  if (userData) currentUsrRoles = [userData.user.role];

  if (route.roles.length === 0) return true;

  const found = currentUsrRoles.some((r) => route.roles.includes(r));
  return found;
};
